<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="'Metody płatności'">
        <template slot="content" slot-scope="{ setLoading }">
          <PaymentsMethods :url="url" @loading="setLoading" />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import PaymentsMethods from "@/components/Statistics/PaymentsMethods";

export default {
  name: "PaymentMethodsStatistics",
  components: {
    Ibox,
    PaymentsMethods
  },
  data() {
    return {
      url: `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/statistics/payment-methods`
    };
  }
};
</script>
