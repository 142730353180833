<template>
  <div>
    <form autocomplete="off" @submit.prevent="handleSubmit">
      <input
        autocomplete="off"
        name="hidden"
        type="text"
        style="display: none"
      />
      <div class="row">
        <div
          class="form-group col-sm-4"
          :class="{ 'has-error': errors.has('dateStart') }"
        >
          <label>
            {{ $t("start_date") }}
          </label>
          <date-picker
            class="NeoDataPicker"
            v-model="dates.dateStart"
            :config="optionsDate"
            name="dateStart"
            autocomplete="off"
            v-validate="'required'"
          />
          <small v-show="errors.has('dateStart')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
        <div
          class="form-group col-sm-4"
          :class="{ 'has-error': errors.has('dateEnd') }"
        >
          <label>
            {{ $t("end_date") }}
          </label>
          <date-picker
            class="NeoDataPicker"
            v-model="dates.dateEnd"
            :config="optionsDate"
            name="dateEnd"
            autocomplete="off"
            v-validate="'required'"
          />
          <small v-show="errors.has('dateEnd')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="btn btn-primary ml-1" @click="set30">
            {{ $t("last_month") }}
          </div>
          <div class="btn btn-primary ml-1" @click="set90">
            {{ $t("last_3_months") }}
          </div>
          <div class="btn btn-primary ml-1" @click="set180">
            {{ $t("last_6_months") }}
          </div>
          <div class="btn btn-primary ml-1" @click="set365">
            {{ $t("last_12_months") }}
          </div>
        </div>
      </div>

      <div class="hr-line-dashed"></div>
      <div class="form-group row">
        <div class="col-sm-4 col-sm-offset-2">
          <button class="btn btn-primary btn-sm" type="submit">Pobierz</button>
        </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-12 col-md-4">
          <span v-if="statistic && statistic.paymentMethods">
            <h2>Statystyki metod płatności</h2>
            <p v-for="(method, i) in statistic.paymentMethods" :key="i">
              {{ method["name"] }}:
              <strong>{{ method["conted_methods"] }}</strong> (
              {{
                Math.round(
                  ((method["conted_methods"] * 100) / conutetPaymentMethod) *
                    100
                ) / 100
              }}% )
            </p>
          </span>
        </div>
        <div class="col-12 col-md-8">
          <canvas id="payments-methods-statistics"></canvas>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "PaymentMethodsStatistics",
  data() {
    return {
      dates: {
        dateStart: null,
        dateEnd: null
      },
      statistic: {},
      conutetPaymentMethod: 0,
      category_list: [],
      paymentsMethodsStatistics: null
    };
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  created() {
    this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
    this.dates.dateStart = this.$moment(this.dates.dateEnd)
      .subtract(1, "months")
      .format("YYYY-MM-DD");
    this.$emit("loading", false);
  },
  methods: {
    set30() {
      this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
    },
    set90() {
      this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(3, "months")
        .format("YYYY-MM-DD");
    },
    set180() {
      this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(6, "months")
        .format("YYYY-MM-DD");
    },
    set365() {
      this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(12, "months")
        .format("YYYY-MM-DD");
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("loading", true);
          this.paymentsMethodsStatistics !== null
            ? this.paymentsMethodsStatistics.destroy()
            : (this.paymentsMethodsStatistics = null);
          this.conutetPaymentMethod = 0;
          this.$http
            .get(
              `${this.url}?dateStart=${this.dates.dateStart}&dateEnd=${this.dates.dateEnd}`
            )
            .then(resp => {
              this.statistic = resp.data;
              if (resp.data && resp.data.paymentMethods) {
                Object.keys(resp.data.paymentMethods).map(key => {
                  this.conutetPaymentMethod +=
                    resp.data.paymentMethods[key].conted_methods;
                  return resp.data.paymentMethods[key].conted_methods;
                });
                console.log(this.conutetPaymentMethod);
                this.paymentsMethodsStatistics = this.createChart(
                  "payments-methods-statistics",
                  resp.data.paymentMethods
                );
              }
              this.$emit("loading", false);
            })
            .catch(err => {
              console.log(err);
              this.$toastr.error(err);
              this.$emit("loading", false);
            });
        }
      });
    },
    createChart(chartId, data) {
      const labels = Object.keys(data).map(value => data[value].name);
      const datasets = Object.keys(data).map(
        value => data[value].conted_methods
      );

      const myChart = new Chart(document.getElementById(chartId), {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              label: "My First Dataset",
              data: datasets,
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(255, 159, 64)",
                "rgb(255, 205, 86)",
                "rgb(75, 192, 192)",
                "rgb(54, 162, 235)",
                "rgb(201, 203, 207)",
                "rgb(153, 102, 255)"
              ]
            }
          ]
        }
      });

      return myChart;
    }
  }
};
</script>
